<template>
  <div>
    <p
      v-for="(error, index) in errors.data_url"
      :key="`error_${index}`"
      class="red--text"
    >{{ error }}</p>
    <v-expansion-panels
      v-model="panels"
      accordion
      multiple
      flat
    >

      <!-- Product -->
      <v-expansion-panel>
        <v-expansion-panel-header color="background">Product
          <span v-if="errors.product" class="text-right mr-16"><v-icon color="error" small>fas fa-exclamation</v-icon></span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="my-5">

          <v-row dense>
            <v-col cols="6">
              <v-radio-group
                v-model="model.is_new_product"
                @change="updateProductColors()"
                row
              >
                <v-radio
                  label="New product"
                  :value="true"
                ></v-radio>
                <v-radio
                  label="Existing product"
                  :value="false"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-if="!model.is_new_product"
                outlined
                v-model="model.product_id"
                :error-messages="errors.product_id"
                :items="products"
                :loading="isSearching"
                :search-input.sync="filter.query"
                label="Search product..."
                item-text="name"
                item-value="id"
                hide-no-data
                hide-selected
                no-filter
                @keyup="productSearch()"
                @change="updateProductColors()"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="model.is_new_product">
            <v-col cols="8">
              <v-text-field
                outlined
                dense
                v-model="model.description"
                :error-messages="errors.description"
                label="Description"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                outlined
                dense
                v-model="model.code"
                :error-messages="errors.code"
                label="Code"
              ></v-text-field>
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="12" class="pt-0">
              <v-simple-table>
                <tbody>
                  <tr
                    v-for="(id, brand) in model.brands"
                    :key="'brand'+brand"
                  >
                    <td class="col-2">Brand</td>
                    <td class="col-6">{{ brand }}</td>
                    <td class="col-3">
                      <v-select
                        outlined
                        v-model="model.brands[brand]"
                        :error-messages="errors['brands.'+brand]"
                        :items="brands"
                        item-text="name"
                        item-value="id"
                        dense
                        hide-details
                        clearable
                        clear-icon="fas fa-times"
                      ></v-select>
                    </td>
                    <td class="col-1 text-right">
                      <v-btn
                        icon
                        @click="showBrandDialog(brand)"
                      ><v-icon small>fas fa-plus</v-icon></v-btn>
                    </td>
                  </tr>
                  <tr
                    v-for="(id, category) in model.categories"
                    :key="'category'+category"
                  >
                    <td class="col-2">Category</td>
                    <td class="col-6">{{ category }}</td>
                    <td class="col-3">
                      <v-select
                        outlined
                        v-model="model.categories[category]"
                        :error-messages="errors['categories.'+category]"
                        :items="categories"
                        item-text="name"
                        item-value="id"
                        dense
                        hide-details
                        clearable
                        clear-icon="fas fa-times"
                      ></v-select>
                    </td>
                    <td class="col-1 text-right">
                      <v-btn
                        icon
                        @click="showCategoryDialog(category)"
                      ><v-icon small>fas fa-plus</v-icon></v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-checkbox
                v-model="model.has_size"
                label="Use size?"
              ></v-checkbox>
            </v-col>
          </v-row>

          <brand-dialog
            v-model="brandDialog"
            :attributes="brand"
            :errors="brandErrors"
            @save="saveBrand"
          ></brand-dialog>

          <category-dialog
            v-model="categoryDialog"
            :attributes="category"
            :errors="categoryErrors"
            :categories="categories"
            @save="saveCategory"
          ></category-dialog>

        </v-expansion-panel-content>

      </v-expansion-panel>


      <!-- Colors -->
      <v-expansion-panel>
        <v-expansion-panel-header color="background">Colors
          <span v-if="errors.colors" class="text-right mr-16"><v-icon color="error" small>fas fa-exclamation</v-icon></span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="my-5">
          <v-simple-table>
            <tbody>
              <tr
                v-for="(id, color) in model.colors"
                :key="'color'+color"
              >

                <td class="col-4">{{ color }}</td>
                <td class="col-1">
                  <v-switch
                    v-if="!model.is_new_product"                  
                    v-model="showAllColors[color]"
                    inset
                    dense
                    label="All"
                    @change="val => switchAllColors(color, val)"
                  ></v-switch>
                </td>

                <td class="col-3">
                  <v-select
                    v-if="model.is_new_product || showAllColors[color]"
                    outlined
                    v-model="selectedColorFamilies[color]"
                    :items="filteredColorFamilies"
                    item-text="name"
                    item-value="id"
                    dense
                    hide-details
                    clearable
                    clear-icon="fas fa-times"
                    @change="model.colors[color] = null"
                  ></v-select>
                </td>

                <td class="col-3">
                  <v-select
                    v-if="!model.is_new_product && !showAllColors[color]"
                    outlined
                    v-model="model.colors[color]"
                    :error-messages="errors['colors.'+color]"
                    :items="productColors"
                    item-text="name"
                    item-value="id"
                    dense
                    hide-details
                    clearable
                    clear-icon="fas fa-times"
                    @change="updateColor(color)"
                  ></v-select>
                  <v-select
                    v-if="model.is_new_product || showAllColors[color]"
                    outlined
                    v-model="model.colors[color]"
                    :error-messages="errors['colors.'+color]"
                    :items="availableColors[color]"
                    item-text="name"
                    item-value="id"
                    dense
                    hide-details
                    clearable
                    clear-icon="fas fa-times"
                    @change="updateColor(color)"
                  ></v-select>
                </td>

                <td class="col-1 text-right">
                  <v-btn
                    v-if="model.is_new_product || showAllColors[color]"
                    icon
                    @click="showColorDialog(color)"
                  ><v-icon small>fas fa-plus</v-icon></v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <color-dialog
            v-model="colorDialog"
            :attributes="color"
            :errors="colorErrors"
            :families="filteredColorFamilies"
            @save="saveColor"
          ></color-dialog>

        </v-expansion-panel-content>

      </v-expansion-panel>

      <!-- Sizes -->
      <v-expansion-panel v-if="model.has_size">
        <v-expansion-panel-header color="background">Sizes
          <span v-if="errors.sizes" class="text-right mr-16"><v-icon color="error" small>fas fa-exclamation</v-icon></span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="my-5">
          <v-simple-table>
            <tbody>
              <tr
                v-for="(id, size) in model.sizes"
                :key="'size'+size"
              >
                <td class="col-5">{{ size }}</td>
                <td class="col-3">
                  <v-select
                    outlined
                    v-model="selectedSizeFamilies[size]"
                    :items="sizeFamilies"
                    item-text="name"
                    item-value="id"
                    dense
                    hide-details
                    clearable
                    clear-icon="fas fa-times"
                    @change="model.sizes[size] = null"
                  ></v-select>
                </td>
                <td class="col-3">
                  <v-select
                    outlined
                    v-model="model.sizes[size]"
                    :error-messages="errors['sizes.'+size]"
                    :items="availableSizes[size]"
                    item-text="name"
                    item-value="id"
                    dense
                    hide-details
                    clearable
                    clear-icon="fas fa-times"
                    @change="updateSize(size)"
                  ></v-select>
                </td>
                <td class="col-1 text-right">
                  <v-btn
                    icon
                    @click="showSizeDialog(size)"
                  ><v-icon small>fas fa-plus</v-icon></v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <size-dialog
            v-model="sizeDialog"
            :attributes="size"
            :errors="sizeErrors"
            :families="sizeFamilies"
            @save="saveSize"
          ></size-dialog>

        </v-expansion-panel-content>

      </v-expansion-panel>

    </v-expansion-panels>

    <div class="mt-5 d-flex flex-row">
      <v-btn
        color="default"
        class="px-5"
        large
        depressed
        @click="nextProduct()"
        :disabled="isActive"
      >Skip</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="px-5"
        large
        depressed
        @click="importProduct()"
        :disabled="isActive"
      >Import</v-btn>
    </div>
  </div>

  
</template>

<script>
/* eslint-disable */
import { inject, ref, watch, computed } from 'vue'
import ColorDialog from '@/components/ColorDialog'
import SizeDialog from '@/components/SizeDialog'
import BrandDialog from '@/components/BrandDialog'
import CategoryDialog from '@/components/CategoryDialog'


export default {
  components: {
    ColorDialog,
    SizeDialog,
    BrandDialog,
    CategoryDialog,
  },
  props: {
    product: Object,
    supplierId: Number,
  },
  setup(props, context) {
    const api = inject('api')
    const loader = inject('loader')

    const { isActive } = loader
    const panels = ref([])

    const colorDialog = ref(false)
    const sizeDialog = ref(false)
    const brandDialog = ref(false)
    const categoryDialog = ref(false)

    const selectedColorFamilies = ref({})
    const selectedSizeFamilies = ref({})
    const showAllColors = ref({})

    const importModel = api.model('products/import', {
      attributes: {
        scenario: 'default',
        is_new_product: true,
        has_size: true,
        product_id: null,
        colors: {},
        sizes: {},
        brands: {},
        categories: {},
      },
      afterSave: () => {
        updateColors()
        updateSizes()
      },
    })
    const { attributes: model, oldAttributes: oldModel, errors } = importModel

    watch(() => props.product, product => {
      console.log('product', product)
      if (props.supplierId) {
        importModel.clear().setAttributes({
          supplier_id: props.supplierId,
          is_new_product: product.is_new_product,
          has_size: product.has_size,
          product_id: product.product_id,
          data_url: product.csv,
          code: product.code,
          description: product.name,
        }).create().then(response => {
          console.log(response)
          showPanels()
          updateProductColors()
          productCollection.setFilter('query', product.code).search()
        })
      }
    }, { immediate: true })



    const colorCollection = api.collection('colors').all()
    const supplierCollection = api.collection('suppliers').all()
    const colorFamiliesCollection = api.collection('color-families').all()
    const sizeCollection = api.collection('sizes').all()
    const sizeFamilyCollection = api.collection('size-families').all()
    const brandCollection = api.collection('brands').all()
    const categoryCollection = api.collection('categories', { params: { filter: { is_disabled: 0 }}}).all()
    const productColorCollection = api.collection()

    const { items: suppliers } = supplierCollection
    const { items: colors } = colorCollection
    const { items: colorFamilies } = colorFamiliesCollection
    const { items: sizes } = sizeCollection
    const { items: sizeFamilies } = sizeFamilyCollection
    const { items: brands } = brandCollection
    const { items: categories } = categoryCollection
    const { items: productColors } = productColorCollection

    const filteredColorFamilies = computed(() => colorFamilies.value.filter(colorFamily => !model.value.is_new_product || colorFamily.is_active))

    // panels
    function showPanels(errorsOnly = false) {
      panels.value = []
      ;['product', 'colors', 'sizes'].forEach((panel, index) => {
        if (!errorsOnly || errors.value[panel]) {
          panels.value.push(index)
        }
      })
    }


    // colors
    const colorModel = api.model('colors/:id')
    const { attributes: color, errors: colorErrors } = colorModel

    function showColorDialog(color) {
      colorDialog.value = true
      colorModel.clear().setAttributes({ name: color, _name: color })
    }

    function saveColor() {
      colorModel.save().then(() => {
        colorDialog.value = false
        colorCollection.all(() => {
          let name = colorModel.getAttribute('_name')
          if (name) {
            model.value.colors[name] = colorModel.getAttribute('id')
            updateColor(name)
          }
        })
      })
    }

    const availableColors = computed(() => {
      let availableColors = {}
      console.log('availableColors')
      Object.keys(model.value.colors).forEach(color => {
        availableColors[color] = colors.value.filter(item => !selectedColorFamilies.value[color] || item.color_family_id == selectedColorFamilies.value[color])
      })
      return availableColors
    })

    function updateColor(color) {
      console.log('updateColor', color)
      let item = colors.value.find(item => item.id == model.value.colors[color])
      selectedColorFamilies.value = Object.assign({}, selectedColorFamilies.value, { [color]: item ? item.color_family_id : null })
      if (showAllColors.value[color] === undefined) {
        showAllColors.value = Object.assign({}, showAllColors.value, { [color]: false })
      }
    }

    function updateColors() {
      Object.keys(model.value.colors).forEach(color => updateColor(color))
    }

    function updateProductColors() {
      if (!importModel.getAttribute('is_new_product') && importModel.getAttribute('product_id')) {
        productColorCollection.setPath(`products/${importModel.getAttribute('product_id')}/colors`).all(() => {
          Object.keys(model.value.colors).forEach(color => switchAllColors(color, false))
        })
      } else {
        productColorCollection.clear()
      }
    }

    function switchAllColors(color, value) {
      if (value) {
        model.value.colors[color] = oldModel.value.colors[color]
        updateColor(color)
      } else {
        let productColor = productColors.value.find(item => item.id == oldModel.value.colors[color])
        model.value.colors[color] = productColor ? productColor.id : null
      }
    }

    // sizes
    const sizeModel = api.model('sizes/:id')
    const { attributes: size, errors: sizeErrors } = sizeModel

    function showSizeDialog(size) {
      sizeDialog.value = true
      sizeModel.clear().setAttributes({ name: size, _name: size })
    }

    function saveSize() {
      sizeModel.save().then(() => {
        sizeDialog.value = false
        sizeCollection.all(() => {
          let name = sizeModel.getAttribute('_name')
          if (name) {
            model.value.sizes[name] = sizeModel.getAttribute('id')
          }
        })
      })
    }

    const availableSizes = computed(() => {
      let availableSizes = {}
      console.log('availableSizes')
      Object.keys(model.value.sizes).forEach(size => {
        availableSizes[size] = sizes.value.filter(item => !selectedSizeFamilies.value[size] || item.size_family_id == selectedSizeFamilies.value[size])
      })
      return availableSizes
    })

    function updateSize(size) {
      console.log('updateSize')
      let item = sizes.value.find(item => item.id == model.value.sizes[size])
      selectedSizeFamilies.value = Object.assign({}, selectedSizeFamilies.value, { [size]: item ? item.size_family_id : null })
    }

    function updateSizes() {
      Object.keys(model.value.sizes).forEach(size => updateSize(size))
    }

    // brands
    const brandModel = api.model('brands/:id')
    const { attributes: brand, errors: brandErrors } = brandModel

    function showBrandDialog(brand) {
      brandDialog.value = true
      brandModel.clear().setAttributes({ name: brand, _name: brand })
    }

    function saveBrand() {
      brandModel.save().then(() => {
        brandDialog.value = false
        brandCollection.all()
        let name = brandModel.getAttribute('_name')
        if (name) {
          model.value.brands[name] = brandModel.getAttribute('id')
        }
      })
    }
    
    // categories
    const categoryModel = api.model('categories/:id')
    const { attributes: category, errors: categoryErrors } = categoryModel

    function showCategoryDialog(category) {
      categoryDialog.value = true
      categoryModel.clear().setAttributes({ name: category, _name: category })
    }

    function saveCategory() {
      categoryModel.save().then(() => {
        categoryDialog.value = false
        categoryCollection.all()
        let name = categoryModel.getAttribute('_name')
        if (name) {
          model.value.categories[name] = categoryModel.getAttribute('id')
        }
      })
    }

    // import
    function importProduct() {
      loader.show()
      importModel.create().then(() => {
        // importModel.clear()
        context.emit('next')
      }).catch(() => {
        showPanels(true)
      }).finally(() => {
        loader.hide()
      })
    }

    function nextProduct() {
      context.emit('next')
    }

    const productCollection = api.collection('products', {
      params: {
        expand: 'category,brand',
      },
      filter: {
        query: 'query:code,unique_name',
      },
    })

    const { items: products, filter, isSearching } = productCollection

    const productSearch = productCollection.debounceSearch(300) 


    return {
      isActive,
      panels,

      showColorDialog,
      colorDialog,
      color,
      colorErrors,
      saveColor,

      showSizeDialog,
      sizeDialog,
      size,
      sizeErrors,
      saveSize,

      showBrandDialog,
      brandDialog,
      brand,
      brandErrors,
      saveBrand,

      showCategoryDialog,
      categoryDialog,
      category,
      categoryErrors,
      saveCategory,

      importProduct,
      nextProduct,

      model,
      errors,
      selectedColorFamilies,
      selectedSizeFamilies,

      suppliers,
      colorFamilies,
      colors,
      sizeFamilies,
      sizes,
      brands,
      categories,

      filteredColorFamilies,

      productColors,
      showAllColors,
      switchAllColors,

      availableColors,
      updateColor,

      availableSizes,
      updateSize,

      products,
      filter,
      isSearching,
      productSearch,
      updateProductColors,

    }

  },
}
</script>


<style scoped>

:deep(.v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover) {
  /* cursor: pointer; */
}

</style>
